import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";
import focus from "@alpinejs/focus";
import { Fancybox } from "@fancyapps/ui";

import tippy, { Instance } from "tippy.js";
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        // new Sentry.BrowserTracing(),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            // maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    ignoreErrors: [/Alpine Expression Error/i],
});

let tippyInstance;

function initTooltips(): void {
    const elements = document.querySelectorAll<Element>(
        '[title]:not([title=""]):not([data-tooltip-enable="false"])',
    );

    elements.forEach((element: Element): void => {
        const titleValue = element.getAttribute("title");
        element.setAttribute("data-tooltip", titleValue || "");
        element.removeAttribute("title");
    });

    if (tippyInstance) {
        tippyInstance.forEach((instance) => {
            instance.destroy();
        });
    }

    tippyInstance = tippy("[data-tooltip]", {
        allowHTML: true,
        theme: "light",
        animation: "shift-away",
        content: (reference) => reference.dataset.tooltip,
    });
}

function onPageReady() {
    Fancybox.bind("[data-fancybox]", {});
    initTooltips();
}

window.addEventListener("updateTooltip", initTooltips);
window.addEventListener("livewire:navigated", function () {
    onPageReady();
});
window.addEventListener("scroll-to-top", function () {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
});
window.addEventListener("setTitle", function (event) {
    window.document.title = event.detail["title"];
});

window.Alpine = Alpine;
window.Livewire = Livewire;
Alpine.plugin(focus);
Livewire.start();
// Alpine.start();
onPageReady();
import.meta.glob("../images/**/*.{jpg,jpeg,png,svg,gif}");
import.meta.glob("../ico/**/*.{jpg,jpeg,png,svg,gif,ico}");
